a {
    color: #3182CE;
    text-decoration: underline;
    cursor: pointer;
}

a:hover {
    color: hotpink;
    text-decoration: underline;

}

/* React-data-table */
.rdt_TableHeadRow {
    font-size: 14px !important;
    height: 35px !important;
    color: #f0e3c4 !important;

}
.rdt_TableRow:hover {
    background-color: #EE3B6F !important;
    color: #f0f0f0 !important;
}

.rdt_Pagination {
    font-size: 14px !important;
}

/* Handson table */
.handsonTableOverride {
    z-index: 10;
    max-height: 670px;
}

/* Custom scrollbar */

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(174, 179, 179);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #3182CE
}

/* End custom scrollbar */